import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslatePipe} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
import {OverlayModule} from '@angular/cdk/overlay';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltip} from '@angular/material/tooltip';
import {MatFormField, MatHint, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDatepicker, MatDatepickerInput} from '@angular/material/datepicker';
import {NgxMaskDirective} from 'ngx-mask';
import {MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable} from '@angular/material/table';

@NgModule({
    imports: [
        // Angular common modules and standalone components
        CommonModule,
        TranslatePipe,
        FormsModule,
        ReactiveFormsModule,
        TextFieldModule,
        CdkTextareaAutosize,
        NgOptimizedImage,

        //Material
        MatProgressSpinner,
        MatMenuModule,
        MatTooltip,
        MatDialogModule,
        MatFormField,
        MatInput,
        MatIcon,
        MatLabel,
        MatHint,
        MatSelect,
        MatOption,
        MatCheckbox,
        MatDatepickerInput,
        MatDatepicker,
        MatTable,
        MatHeaderCell,
        MatCell,
        MatHeaderRow,
        MatRow,
        MatColumnDef,
        MatHeaderRowDef,
        MatRowDef,
        MatHeaderCellDef,
        MatCellDef,

        //Other Cdk
        ScrollingModule,
        OverlayModule,
        NgxMaskDirective
    ],
    exports: [
        // Angular common modules and standalone components
        CommonModule,
        TranslatePipe,
        FormsModule,
        ReactiveFormsModule,
        TextFieldModule,
        CdkTextareaAutosize,
        NgOptimizedImage,

        //Material
        MatProgressSpinner,
        MatMenuModule,
        MatTooltip,
        MatDialogModule,
        MatFormField,
        MatInput,
        MatIcon,
        MatLabel,
        MatHint,
        MatSelect,
        MatOption,
        MatCheckbox,
        MatDatepickerInput,
        MatDatepicker,
        MatTable,
        MatHeaderCell,
        MatCell,
        MatHeaderRow,
        MatRow,
        MatColumnDef,
        MatHeaderRowDef,
        MatRowDef,
        MatHeaderCellDef,
        MatCellDef,

        //Other Cdk
        ScrollingModule,
        OverlayModule,
        NgxMaskDirective
    ]
})

export class AngularComponentsModule {

}
