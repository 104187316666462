import {Injectable} from '@angular/core';
import {ReloginRequestDto} from '../../../../services/authentication/model/reloginRequestDto';
import {v4} from 'uuid';

@Injectable({
    providedIn: 'root'
})

export class ConfigService {
    deviceAndBrowserUuid = {
        randomUUID: v4()
    };

    /** Credentials */
    authCredentials = {
        clientId: ReloginRequestDto.ClientIdEnum.OVNCABINETWEB,
        clientType: ReloginRequestDto.ClientTypeEnum.WEB,
    };

    /** Google maps config */
    googleMapsConfig: google.maps.MapOptions = {
        center: {
            lat: 39.5,
            lng: -98.35,
        },
        zoom: 5,
        mapTypeControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: true,
    };

    contentViewerDialog = {
        closeOnNavigation: true,
        disableClose: true,
        width: '100%',
        height: '100%',
        panelClass: ['content-viewer-dialog-container']
    };

    defaultDialogConfig = {
        width: '100%',
        maxWidth: '500px',
        maxHeight: '100%',
        panelClass: ['base-dialog-container'],
        closeOnNavigation: true,
        hasBackdrop: true,
    };

    maxFileSize = 31457280;

    searchTimeout = 600;
}
