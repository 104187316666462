/**
 * OVN Authentication Service
 * Provides login/register related API.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ReloginRequestDto { 
    clientId?: ReloginRequestDto.ClientIdEnum;
    clientType?: ReloginRequestDto.ClientTypeEnum;
    deviceId?: string;
    refreshToken?: string;
}
export namespace ReloginRequestDto {
    export type ClientIdEnum = 'OVN_CLIENT_MOBILE' | 'OVN_CABINET_WEB' | 'OVN_DISPATCH_WEB' | 'TRACKING_APP';
    export const ClientIdEnum = {
        OVNCLIENTMOBILE: 'OVN_CLIENT_MOBILE' as ClientIdEnum,
        OVNCABINETWEB: 'OVN_CABINET_WEB' as ClientIdEnum,
        OVNDISPATCHWEB: 'OVN_DISPATCH_WEB' as ClientIdEnum,
        TRACKINGAPP: 'TRACKING_APP' as ClientIdEnum
    };
    export type ClientTypeEnum = 'IOS' | 'ANDROID' | 'WEB';
    export const ClientTypeEnum = {
        IOS: 'IOS' as ClientTypeEnum,
        ANDROID: 'ANDROID' as ClientTypeEnum,
        WEB: 'WEB' as ClientTypeEnum
    };
}